<template>
	<div class="single-view tool-http-debugger">
		<div class="tool-box" :class="isLoading ? 'tool-redirects-loading' : ''">
			<h1>{{ ii('HTTP_RESPONSE_DEBUGGER') }}</h1>
			<div>
				<div class="tool-input-box">
					<s-text-field
						type="text"
						v-model="app.query.url"
						prepend-icon="globe"
						ref="tf"
						:placeholder="ii('URL')"
						:loading="isLoading"
						:error="error"
						@change="onChange"
					></s-text-field>
				</div>
				<div class="debug-items">
					<table class="result-tbl">
						<tbody v-for="(item, index) in items">
							<tr @click="changeShowStateForItem(index)">
								<td colspan="2" class="item-url-cell pointer">
									<div class="item-info">
										<span class="item-url">{{ item.url }}</span>
										<span class="item-code">{{ item.code }}</span>
									</div>
								</td>
							</tr>
							<template v-if="itemsShowState[index]">
								<tr v-if="item.error">
									<td colspan="2" class="item-error">
										{{ item.error }}
									</td>
								</tr>
								<template v-else>
									<template v-for="header in item.headers">
										<tr>
											<td class="header-name">{{ header.key }}</td>
											<td class="header-val">{{ header.val }}</td>
										</tr>
									</template>
								</template>
							</template>
						</tbody>
					</table>
				</div>
				<div class="page-text-content" v-html="markdownToHtml(app.texts.content)"></div>

			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			items: null,
			itemsShowState: [],
			error: null,
			isLoading: false
		};
	},
	methods: {
		async getChain() {
			this.error = null;

			if (this.isLoading) return;

			let url = this.app.query.url;
			if (!url || !url.trim()) {
				this.items = null;
				return;
			}

			this.hit('redirect_url_enter', url);

			this.isLoading = true;
			try {
				let res = await this.get('HTTPDebugger.getRedirectChain', url);
				this.items = res.items;
			} catch (err) {
				this.error = err.message;
				this.items = null;
			}
			this.setShowStateForItems();

			this.isLoading = false;
			this.$refs.tf.focus();
		},
		setShowStateForItems(){
			if(this.items == null){
				this.itemsShowState = [];
				return;
			}
			this.items.forEach((itm, index) => {
				if((index + 1) == this.items.length)
					this.itemsShowState[index] = true;
				else
					this.itemsShowState[index] = false;
			});
		},
		changeShowStateForItem(index){
			this.$set(this.itemsShowState, index, !this.itemsShowState[index]);
		},

		onChange() {
			this.refreshQuery();
			this.getChain();
		}
	},
	mounted() {
		this.$refs.tf.focus();
		this.getChain();
	}
}
</script>

<style lang="less">
@import "../styles/helpers";

.tool-http-debugger {
	.debug-items {
		.transition(all 0.1s);

		td.item-url-cell {
			background: #eee !important;
		}

		.item-info {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.item-url {
				width: 85%;
				padding: 5px 10px;
				word-break: break-word;
				font-size: 15px;

				a {
					font-size: 13px;
				}
			}

			.item-code {
				border: 1px solid #ddd;
				padding: 1px 5px 3px 5px;
				font-weight: 500;
				font-size: 16px;
				min-width: 50px;
				text-align: center;
				background: #fff;
			}
		}

		.item-error {
			display: inline-block;
			padding: 2px 5px;
			font-weight: 500;
			font-size: 14px;
			color: #f00;
		}
		.header-val{
			max-width: 350px;
			word-wrap: break-word;
		}
		.header-name{
			max-width: 100px;
			word-wrap: break-word;
		}
	}

	&.tool-redirects-loading .redirect-items {
		opacity: 0.3;
	}

	.result-tbl {
		> tr, > tbody > tr {
			max-width: 450px;
			> td:nth-child(1) {
				text-transform: none !important;
			}
		}
	}
}
</style>
